import { useEffect, useState } from "react"
const UseStateIssue = () => {
  const [timeDate, setTimeDate] = useState('00:00:00')
  useEffect(()=>{
    const interval = setInterval(()=>{
      console.log('timeDate', timeDate)
    }, 2000)
    return () => { // timeDate change -> return
      console.log('useEffect return')
      clearInterval(interval)
    }
  }, [timeDate]) // keypoint: timeDate here
  return (
    <div>
      <div>{timeDate}</div>
      <button onClick={()=>{
        setTimeDate(new Date().toLocaleTimeString())
        }}>setTimeDate</button>
    </div>
  )
}
export default UseStateIssue
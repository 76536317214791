import { useEffect } from "react"
import { observer } from "mobx-react-lite"

const Dev = observer(() => {
  useEffect(()=>{
    foo()
    const interval = setInterval(()=>{
      foo()
    }, 3000)
    return () =>{
      clearInterval(interval)
    }
  }, [])
  
  function foo() {
    console.log('foo')
  }
  return (
    <div>
      
    </div>
  )
})
export default Dev
import React from 'react'
import './App.css';
import { createTheme, ThemeProvider } from '@mui/material/styles'
import {useMediaQuery, CssBaseline} from '@mui/material'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Words from './pages/Words/Words';
import Dev from './pages/Dev/Dev';
import UseStateIssue from './pages/Dev/UseStateIssue';

function App() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: prefersDarkMode ? 'light' : 'light', // dark
        },
      }),
    [prefersDarkMode],
  )
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Routes>
          <Route path='/' element={<Words />} />
          <Route path='/dev' element={<Dev />} />
          <Route path='/dev/use-state-issue' element={<UseStateIssue />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
